html,
body,
#root,
.app {
  box-sizing: border-box;
  height: 100%;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
}

h1,
p {
  margin: 0;
  padding: 0;
}
